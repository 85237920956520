<template>
    <v-card class="mt-4 mx-2 elevation-0">
        <FtRegistrasiApprovalTable></FtRegistrasiApprovalTable>
    </v-card>
  </template>
  
  <script>
  import FtRegistrasiApprovalTable from "@/components/admin_approval/approval/FtRegistrasiApprovalTable.vue";
  
  export default {
    name: "ApprovalView",
    components: {
      FtRegistrasiApprovalTable,
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {

    },
  
  
  }
  </script>
  
  <style scoped>
  </style>