<template>
  <v-card class="elevation-0">
    <v-card-title>
      <v-icon color="orange">mdi-star-circle</v-icon> {{ title }}
      <v-spacer></v-spacer>

      <v-text-field
        v-on:keyup.enter="searchOnEnter"
        v-on:blur="searchOnEnter"
        append-icon="mdi-magnify"
        hint="Kode, Deskripsi (press ENTER to search)"
        label="Search"
      ></v-text-field>
      <v-btn @click="showFilterDialog" icon fab color="blue" small class="ml-2">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <v-card
        class="ml-2 mr-2 elevation-0 grey lighten-4"
        v-show="showFilter === true"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                v-model="filterFdivisions"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                multiple
                label="Dinas/OPD"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="8">
              <v-autocomplete
                v-model="filterFtemplateReg"
                :items="itemsFTemplateReg"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Jenis Registrasi"
                multiple
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn color="primary" small @click="runExtendedFilter">
                Terapkan
                <v-icon small class="ml-1" color="green lighten-2"
                  >mdi-filter</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-data-table
      v-model="selectedItems"
      :single-select="!multiSelect"
      :show-select="multiSelect"
      :headers="headers"
      :items="ftRegistrasiFiltered"
      :page.sync="currentPage"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-0"
      @page-count="totalTablePages = totalPaginationPages"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
            v-model="multiSelect"
            :label="multiSelect ? 'Multi Select' : 'Single Select'"
            x-small
            class="pa-3"
          ></v-switch>
          <v-btn
            v-if="false"
            fab
            dark
            color="red accent-4"
            x-small
            :disabled="!multiSelect"
            class="mr-2"
            @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab dark x-small color="blue" v-bind="attrs" v-on="on">
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>
            </template>
            <v-list color="grey lighten-4" class="mr">
              <v-list-item>
                <v-btn
                  plain
                  elevation="0"
                  color="gray darken-1"
                  small
                  @click="importDialogShow"
                >
                  <v-icon color="green">mdi-upload</v-icon>
                  Import from Template
                </v-btn>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-btn
                  plain
                  elevation="0"
                  color="gray darken-1"
                  small
                  @click="exportData"
                >
                  <v-icon color="green" small class="mr-2"
                    >mdi-file-excel</v-icon
                  >
                  Export Excel
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn
                  plain
                  elevation="0"
                  color="gray darken-1"
                  small
                  @click="exportData"
                >
                  <v-icon color="orange" class="mr-2">mdi-zip-box</v-icon>
                  Download Zip
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            fab
            dark
            color="green"
            x-small
            @click="showDialogNew"
            :disabled="multiSelect"
            class="ml-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.validasiStatus`]="{ item }">
        <v-icon color="success" large>mdi-check-circle</v-icon>
        {{ item.validasiStatus ? "" : "" }}
      </template>

      <template v-slot:[`item.approvedStatus`]="{ item }">
        <v-chip
          @click="showDialogEdit(item)"
          class="ma-2"
          :color="
            item.approvedStatus === 0
              ? 'orange'
              : item.approvedStatus === 1
              ? 'green'
              : item.approvedStatus === 2
              ? 'red'
              : 'grey'
          "
          dark
        >
          <v-icon pencil dark class="mr-3" large>mdi-pencil-circle</v-icon>
          {{
            item.approvedStatus === 0
              ? "Menunggu"
              : item.approvedStatus === 1
              ? "Valid"
              : item.approvedStatus === 2
              ? "Tidak Valid"
              : "-"
          }}
        </v-chip>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip :color="getColorStatusActive(item.statusActive)" dark small>
          {{ item.statusActive === true ? "Aktif" : "-" }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ index + (currentPage - 1) * pageSize + 1 }}
      </template>

      <template v-slot:[`item.avatar`]="{ item }">
        <v-img
          :lazy-src="lookupImageUrlLazy(item)"
          :src="lookupImageUrl(item)"
          alt="avatar"
          width="70px"
          height="70px"
          class="ma-2 rounded"
        >
        </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1">
          {{ item.description }}
        </div>
        <div class="font-weight-bold">
          {{ item.nip }}
        </div>
      </template>

      <template v-slot:[`item.ftemplateRegBean`]="{ item }">
        <div class="blue--text font-weight-bold">
          {{ lookupFTemplateReg(item.ftemplateRegBean).description }}
        </div>
        <div>
          {{ item.periodeYear }}
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="text-caption small">
          {{ lookupFDivision(item.fdivisionBean).description }}
        </div>
        <div>
          <v-chip :color="getColorStatusActive(item.statusActive)" dark x-small>
            {{ item.statusActive === true ? "Aktif" : "-" }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }" v-if="false">
        <v-btn @click="showDialogEdit(item)" icon :disabled="multiSelect">
          <v-icon small color="warning"> mdi-pencil </v-icon>
        </v-btn>

        <v-btn
          v-if="false"
          @click="deleteDialogShow(item)"
          icon
          :disabled="multiSelect"
        >
          <v-icon small color="red accent-4"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col cols="4" md="2" sm="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per page"
          ></v-select>
        </v-col>
        <v-col cols="10" md="9" sm="8" align="right">
          <v-pagination
            v-model="currentPage"
            :length="totalPaginationPages"
            total-visible="8"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
      @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <FtRegistrasiDialog
      :formMode.sync="formMode"
      :itemsFDivision="itemsFDivision"
      :itemsFTemplateReg="itemsFTemplateReg"
      ref="refFormDialog"
      @eventFromFormDialogNew="saveDataNew"
      @eventFromFormDialogEdit="saveDataEdit"
    ></FtRegistrasiDialog>

    <ConfirmDialog
      ref="refConfirmDialog"
      @eventFromOkeConfirmDialog="confirmDialogOke"
    ></ConfirmDialog>

    <v-dialog width="100px" v-model="dialogLoading">
      <v-card class="px-4 py-7">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="40"
              :width="5"
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import FtRegistrasiService from "../../../services/apiservices/ft-registrasi-service";
import FDivisionService from "../../../services/apiservices/f-division-service";
import FTemplateRegService from "../../../services/apiservices/f-template-reg-service";
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";
import FtRegistrasiDialog from "./FtRegistrasiApprovalDialog.vue";

import FormMode from "../../../models/form-mode";
import FtRegistrasi from "../../../models/ft-registrasi";
import FileService from "../../../services/apiservices/file-service";
import FtRegistrasiFilter from "@/models/payload/ft-registrasi-filter";

import ESchemaSalesCs from "@/models/e-schema-sales-cs";
import writeXlsxFile from "write-excel-file";
import FileSaver from "file-saver";

export default {
  components: {
    FtRegistrasiDialog,
    DeleteConfirmDialog,
    ConfirmDialog,
  },
  data() {
    return {
      title: "APPROVAL",
      snackbar: false,
      snackBarMesage: "",

      dialogLoading: false,
      loading: false,

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500, 1500],

      showFilter: true,
      filterFdivisions: [],
      filterFtemplateReg: [],
      filterFareaBean: [],

      search: "",

      headers: [
        {
          text: "No",
          value: "number",
          width: "8%",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "avatar",
        },
        // { text: 'NIP', value: 'nip', width:"10%" },
        { text: "NIP & Nama PNS", value: "description" },
        { text: "USULAN/PENGAJUAN", value: "ftemplateRegBean" },

        { text: "Tanggal Usulan", value: "trDate" },
        { text: "Riwayat Usulan", value: "fsubAreaBean" },
        { text: "Valid", value: "validasiStatus" },
        { text: "Status Approval", value: "approvedStatus" },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      ftRegistrasi: [new FtRegistrasi(0, "", "")],
      itemsFDivision: [{ id: 0, kode1: "", description: "" }],
      itemsFTemplateReg: [],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFtRegistrasi();
      },
    },
    pageSize: {
      handler: function (value) {
        const refreshData = this.currentPage === 1;
        this.currentPage = 1;
        if (refreshData) {
          console.log("Change PageSize " + value);
          this.fetchFtRegistrasi();
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      },
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    ftRegistrasiFiltered() {
      return this.ftRegistrasi;
    },
  },
  methods: {
    showFilterDialog() {
      this.showFilter = !this.showFilter;
    },

    runExtendedFilter() {
      const extendedFilter = new FtRegistrasiFilter();
      extendedFilter.ftemplateRegIds = this.filterFtemplateReg;
      const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
      if (this.filterFtemplateReg.length===0 && hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
        extendedFilter.ftemplateRegIds = this.itemsFTemplateReg.map(
            (item) => item.id
        );
      }

      extendedFilter.fdivisionIds = this.filterFdivisions;
      if (extendedFilter.fdivisionIds.length === 0 && hasAdminOrApproval) {
        extendedFilter.fdivisionIds = this.itemsFDivision.map(
            (item) => item.id
        );
      }

      extendedFilter.fareaIds = this.filterFareaBean;

      if (extendedFilter.fdivisionIds.length === 0)
        extendedFilter.fdivisionIds = [];

      // console.log(`>> ${JSON.stringify(extendedFilter.ftemplateRegIds)}`)

      extendedFilter.pageNo = this.currentPage;
      extendedFilter.pageSize = this.pageSize;
      extendedFilter.sortBy = "id";
      extendedFilter.order = "DESC";
      extendedFilter.search = this.search;

      extendedFilter.city = "";

      FtRegistrasiService.getPostAllFtRegistrasiContainingExt(
        extendedFilter
      ).then(
        (response) => {
          // console.log(JSON.stringify(response.data.items))

          const { items, totalPages } = response.data;
          this.ftRegistrasi = items;
          this.totalPaginationPages = totalPages;
        },
        (error) => {
          console.log(error);
        }
      );
    },

    searchOnEnter(event) {
      if (this.search !== event.target.value) {
        //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1;
        this.search = event.target.value;
        this.fetchFtRegistrasi();
      }
    },

    importDialogShow() {
      this.$refs.refImportDialog.showDialog();
      // console.log("hello bos")
    },

    importExcelCompleted(value) {
      if (value === "OKE") {
        this.fetchFtRegistrasi();
      }
    },

    exportData() {
      let schema = ESchemaSalesCs;

      let fileBits = "file_registrasi.xlsx";

      let ftDataSorted = this.ftRegistrasi.slice().sort(function (a, b) {
        return a.city > b.city ? 1 : -1;
      });

      let ftDataToShowReports = [];
      ftDataSorted.forEach((item) => {
        let ftData = item;
        ftData.fdivision = this.lookupFDivision(ftData.fdivisionBean);

        ftDataToShowReports.push(ftData);
      });

      writeXlsxFile(ftDataToShowReports, {
        schema,
        fileName: fileBits,
      });

      let file = new File(fileBits, "file_data.txt", {
        type: "xlsx/plain;charset=utf-8",
      });
      FileSaver.saveAs(file);
    },

    fetchParent() {
      const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));

      if (! hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
          (response) => {
            this.itemsFDivision = [response.data];
          },
          (error) => {
            console.log(error.response);
          }
        );
      } else {
        FDivisionService.getAllFDivision().then(
          (response) => {
            this.itemsFDivision = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      // FTemplateRegService.getAllFTemplateReg().then(
      //   (response) => {
      //     if (hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
      //       this.itemsFTemplateReg =  response.data.filter(
      //           (item) => item.fdivisionBean === this.currentUser.fdivisionBean
      //       )
      //     }else {
      //       this.itemsFTemplateReg = response.data;
      //     }
      //   },
      //   (error) => {
      //     console.log(error.response);
      //   }
      // )


    },
    fetchFtRegistrasi() {
      this.runExtendedFilter();
    },
    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },

    confirmDialogOke() {
      this.snackBarMesage = "";
      this.dialogLoading = true;
      this.loading = true;
    },
    showDialogNew() {
      this.itemSelectedIndex = -1;
      const itemModified = Object.assign({}, "");
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM;
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified);
    },
    saveDataNew(itemFromRest) {
      this.itemSelected = itemFromRest;
      this.closeDialog();
      /**
       * Setiap data baru harus terlihat
       */
      this.fetchFtRegistrasi();
    },

    showDialogEdit(item) {
      this.itemSelectedIndex = this.ftRegistrasiFiltered.indexOf(item);
      const itemModified = Object.assign({}, item);
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM;
      this.$refs.refFormDialog.showDialog(
        this.itemSelectedIndex,
        itemModified,
        this.itemsFDivision
      );
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      try {
        Object.assign(
          this.ftRegistrasi[this.itemSelectedIndex],
          this.itemSelected
        );
      } catch (e) {
        this.fetchFtRegistrasi();
        e.toString();
      }
      this.closeDialog();
    },

    deleteDialogShow(item) {
      // console.log(item)
      this.itemSelectedIndex = this.ftRegistrasiFiltered.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(
        this.itemSelectedIndex,
        item.username
      );
    },
    deleteDialogMultiShow() {
      if (this.multiSelect === true) {
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(
          this.selectedItems,
          `${this.selectedItems.length} items selected`
        );
      }
    },
    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.ftRegistrasi[this.itemSelectedIndex];
      FtRegistrasiService.deleteFtRegistrasi(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.ftRegistrasi.splice(this.itemSelectedIndex, 1);
          this.closeDialog();
        },
        (error) => {
          console.log(error);
          this.snackBarMesage = "gagal hapus (digunakan oleh data anakk)";
          this.snackbar = true;
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
        }
      );
    },
    deleteItemConfirmedMultiSelect(items) {
      // console.log(okeMessage)
      if (items.length > -1) {
        let itemIds = [];
        for (let i = 0; i < items.length; i++) {
          itemIds.push(items[i].id);
        }
        // console.log("Item idex: " + itemIds)

        FtRegistrasiService.deleteAllFtRegistrasi(itemIds).then(
          () => {
            // console.log(response.data)

            if (this.showFilter) {
              this.runExtendedFilter();
            } else {
              this.fetchFtRegistrasi();
            }
            this.selectedItems = [];
            this.closeDialog();
          },
          (error) => {
            console.log("error " + error);
          }
        );
      }
    },
    closeDialog() {
      // this.myConfirmDialog = false
      this.formMode = "";
      this.$refs.refDeleteConfirmDialog.setDialogState(false);
      this.$refs.refFormDialog.setDialogState(false);

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FtRegistrasi());
        this.itemSelectedIndex = -1;
      });
    },

    getColorStatusActive(trueFalse) {
      if (trueFalse === true) return "green";
      else if (trueFalse === false) return "gray";
      else return "gray";
    },

    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFTemplateReg(ftemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === ftemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_low(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.fetchParent()

      const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
      FTemplateRegService.getAllFTemplateReg().then(
          (response) => {
            if (hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
              this.itemsFTemplateReg =  response.data.filter(
                  (item) => item.fdivisionBean === this.currentUser.fdivisionBean
              )
            }else {
              this.itemsFTemplateReg = response.data;
            }

            this.filterFtemplateReg = this.itemsFTemplateReg.map((item) => item.id);
            this.fetchFtRegistrasi();

          },
          (error) => {
            console.log(error.response);
          }
      )



    }
  },
};
</script>

<style scoped>
</style>